.App, html, body, #root {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
.App {
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}