.ProjectsContainer{
    margin: 200px 0px 0px 0px;
    width: 50%;
    display: block;
    padding: 36px;
}
.ProjectsContainer h2 {
    font-size: 20px;
    color: black
}
.ProjectsContainer h1 {
    margin-top: 0px;
    font-size: 30px;
    color: black
}

/* PLUS */
.Plus {
    font-size: 25px;
    margin: 20px 0;
    cursor: pointer;
    color: var(--yellow);
}

/* HEADER */
.ProjectImg {
    height: 100px;
    min-width: 150px;
    background-size: cover;
    background-position: center;
    margin-right: 10px;

    background-color: var(--yellow);
}
.Header {
    background-color: #fff;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);

    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    margin: 20px 0px;
    padding-left: 0px;
}
.Edit, .Chevron {
    font-size: 20px;
    margin: 0 20px;
    margin-right: 0px;
    cursor: pointer;
}

/* PROJECT FORM */
.ProjectForm {
    margin: 0px;
    background-color: var(--light-grey);
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
}
input, textarea, button, select {
    width: 100%;
    font-family: 'DINPro-Light', sans-serif;
    margin: 5px 0;
    font-size: 20px;
    padding: 10px;
    outline: none;
    border: none;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);
}
input::placeholder, textarea::placeholder, select::placeholder {
    opacity: 0.5;
}
input:focus, textarea:focus {
    box-shadow: none;
}

/* PROJECT TYPE */
.TypeSection {
    display: block
}
.TypeSection input {
    box-shadow: none;
    width: fit-content;
    margin-right: 10px;
}
.TypeOption {
    display: flex;
    align-items: center;
    font-size: 20px;
}
.TypeOption label {
    margin: 0;
}

/* IMAGES */
.ImgSummary {
    width: fit-content;
    height: fit-content;
    margin-right: 10px;
}
.ImgThumbnail {
    height: 100px;
    width: 100px;
    background-size: cover;
    background-position: center;

    position: relative;
}
.ImgName {
    word-wrap: break-word;
    font-size: 0.8em;
    width: 100px;
}

.VidFormat {
    width: 135px;
    box-shadow: none;
    cursor: pointer;
}
.VidName {
    margin: 0;
}
.Thumbnails {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
}
.Thumbnails p {
    margin-top: 5px
}
.ImageSubmit, .VideoSubmit {
    background-color: #fff;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 5px;

    display: flex;
    justify-content: space-between;
    justify-content: center;
}
.ImageSubmit h3, .VideoSubmit h3 {
    margin: 5px 0px;
}
.UploadImages, .UploadVideo {
    box-shadow: none;
    padding: 0px;
}
.Trash {
    font-size: 20px;
    color: var(--white);
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.TrashVid {
    color: black;
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
}

/* DELETE BUTTON */
.Delete {
    display: flex;
    align-items: center;
}
.DeleteButton, .SubmitButton {
    background-color: var(--yellow);
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);
    color: black;
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    padding: 20px;
    width: 135px;
    margin: 0;
}
.DeleteButton {
    margin: 10px 0px;
}    
.DeleteConfirmation {
    margin-left: 20px;
    display: none;
}
.DeleteConfirmation p {
    color: var(--black);
    font-size: 20px;
    margin: 0px 10px;
}
.DeleteYes, .DeleteNo {
    transition: all 0.2s ease;
    cursor: pointer;
}
.DeleteYes:hover, .DeleteNo:hover {
    transform: scale(1.01);
    border-bottom: 2px solid black;
    cursor: pointer;
}
.DeleteYes:active, .DeleteNo:active {
    transform: scale(0.99);
    border-bottom: 2px solid black;
    cursor: pointer;
}

/* SUBMIT BUTTON */
.SubmitButton {
    margin-right: 10px;
    background-color: black;
    color: white;
    transition: all 0.2s ease;
}
.SubmitButton:hover, .DeleteButton:hover{
    transform: scale(1.01);
}
.SubmitButton:active, .DeleteButton:active{
    transform: scale(0.99);
}

@media (max-width: 1100px) and (min-width: 700px) {
    .ProjectsContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .Header, .ProjectForm, .MainSubmitButton {
        width: 70vw;
    }
    .DeleteNo, .DeleteYes {
        border-bottom: 1.5px solid black;
    }
    .DeleteButton {
        margin: 2.5vw 0px;
    }    
}

@media (max-width: 700px) {
    .ProjectsContainer{
        margin: 60px 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .Header, .ProjectForm {
        width: 90vw;
        position: relative;
    }
    .ProjectForm {
        padding: 7vw;
    }

    .Header h2, .ProjectForm h2 {
        font-size: 5vw;
    }
    .Header svg {
        font-size: 5vw;
        position: absolute;
        top: 50%;
        right: 2.5vw;
        transform: translateY(-50%);
    }
    .ProjectForm h1 {
        font-size: 7.5vw;
    }
    .ProjectForm label, .ProjectForm input, .ProjectForm textarea, .ProjectForm button, .ProjectForm select, .DeleteButton {
        font-size: 5vw;
    }

    input, textarea, button {
        width: 100%;
    }
    
    .Delete {
        display: block;
    }
    .DeleteConfirmation {
        padding-top: 10px;
        margin-left: 0;
        justify-content: center;
    }
    .DeleteConfirmation p {
        font-size: 5vw;
    }
    
    .DeleteNo, .DeleteYes {
        border-bottom: 1.5px solid black;
    }
    .DeleteButton {
        margin: 2.5vw 0px;
    }
}