.AuthContainer {
    /* padding: 0px 100px; */
    width: 500px;
    margin-top: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.AuthContainer h1 {
    color: var(--yellow);
}
.AuthContainer p {
    text-align: center;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 1px;
    color: var(--white);
}

.AuthForm {
    width: 100%;
    margin: 0px;
    padding: 0px;
}
.AuthForm label {
    font-size: 18px;
    line-height: 30px;
    color: var(--white);
}

.AuthLink {
    font-weight: 500;
}
.AuthLink:hover {
    color: var(--yellow);
    border-bottom: 1.5px var(--yellow) solid;
}

.ErrorMessage {
    /* color: var(--white); */
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 1px;
    padding: 5px 0px;
}

@media (max-width: 700px){
    .AuthContainer {
        width: 80vw;
        margin-top: 50px
    }
    .AuthContainer h1 {
        font-size: 8vw;
        text-align: center;
    }
    .AuthContainer p, .AuthForm label {
        font-size: 5vw;
    }
}