.Content{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

a {
    color: var(--white);
    text-decoration: none;
}

a:visited {
    text-decoration: none;
    color: var(--white);
}