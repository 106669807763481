.Main {
  height: fit-content;
  margin-top: 160px;
  width: 100%;
  box-sizing: border-box;
  padding: 72px 36px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 41vw auto;
  column-gap: 20px;
  row-gap: 24px;
}

.Main .HeroImg {
  height: 41vw;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  grid-column: 2;
}

.Main .Info {
  padding-right: 36px;
  grid-column: 1;
  grid-row: 1 / span 2;
}

.Main .Info h1 {
  font-size: 20px;
  color: var(--yellow);
  margin: 0px;
  line-height: 0.9cm;
  margin-bottom: 5px;
}
.Main .Info h3 {
  color: var(--white);
  margin: 0px;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
}
.Main .Info p {
  color: var(--white);
  font-weight: 300;
  font-size: 14px;
  line-height: 25px;
}
.Main .Info a {
  text-decoration: underline;
}
.Main .Info a:hover {
  transition: all ease 0.2s;
  color: black;
}

.Main .Images {
  grid-column: 2;
  grid-row: 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 24px;
}
.Main .Images .ImgL {
  height: 41vw;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  grid-column: span 2;
}
.Main .Images .ImgV {
  height: 45vw;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  grid-column: span 1;
}
.Main .Video {
  cursor: pointer;
  height: 41vw;
  grid-column: 2;
  background-color: black;
}
.Video iframe {
  cursor: pointer;
  height: 41vw;
  grid-column: 2;
}

@media (max-width: 800px) and (min-width: 700px) {
  .Main {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 59vw auto;
    padding: 36px 36px;
  }
  .Main .HeroImg {
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
    height: 59vw;
  }
  .Main .Info {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
  }
  .Main .Images {
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
    display: block;
  }
  .Main .Images .ImgL,
  .ImgV {
    margin-bottom: 24px;
  }
  .Main .Images div:last-child {
    margin-bottom: 0;
  }
  .Main .Images .ImgL {
    height: 28.8vw;
  }
  .Main .Images .ImgV {
    height: 64.3vw;
  }
  .Main .Video {
    height: 59vw;
    grid-column: 1 / span 2;
  }
  .Video iframe {
    height: 59vw;
    grid-column: 1 / span 2;
  }
}

@media (max-width: 700px) {
  .Main {
    align-self: flex-start;
    margin: 0px;
    margin-top: 10vh;
    padding: 5vw;
    grid-template-columns: 1fr;
    grid-template-rows: 58.3vw auto auto;
    row-gap: 5vw;
  }
  .Main .HeroImg {
    height: 58.3vw;
    grid-column: 1;
    grid-row: 1;
  }
  .Main .Info {
    padding-right: 0px;

    grid-column: 1;
    grid-row: 2;
  }
  .Main .Info h1 {
    color: var(--yellow);
    margin: 0px;
    font-size: 5vw;
    line-height: 8vw;
    margin-bottom: 5px;
  }
  .Main .Info h3 {
    color: var(--white);
    margin: 0px;
    font-weight: 500;
    font-size: 3.5vw;
    line-height: 5vw;
  }
  .Main .Info p {
    color: var(--white);
    font-weight: 300;
    font-size: 3vw;
    line-height: 5vw;
  }
  .Main .Images {
    grid-column: 1;
    grid-row: 3;

    display: block;
  }
  .Main .Images .ImgL,
  .ImgV {
    margin-bottom: 5vw;
  }
  .Main .Images div:last-child {
    margin-bottom: 0;
  }
  .Main .Images .ImgL {
    height: 58.3vw;
  }
  .Main .Images .ImgV {
    height: 131.5vw;
  }
  .Main .Video {
    height: 58.3vw;
    grid-column: 1;
  }
  .Video iframe {
    height: 58.3vw;
    grid-column: 1;
  }
}

/* .Main {
  height: fit-content;
  margin-top: 160px;
  width: 100%;
  box-sizing: border-box;
  padding: 72px 36px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 44vw auto;
  column-gap: 20px;
  row-gap: 24px;
}

.Main .HeroImg {
  height: 44vw;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  grid-column: 2;
}

.Main .Info {
  padding-right: 36px;
  grid-column: 1;
  grid-row: 1 / span 2;
}

.Main .Info h1 {
  font-size: 20px;
  color: var(--yellow);
  margin: 0px;
  line-height: 0.9cm;
  margin-bottom: 5px; 
}
.Main .Info h3 {
  color: var(--white);
  margin: 0px;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
}
.Main .Info p {
  color: var(--white);
  font-weight: 300;
  font-size: 14px;
  line-height: 25px;
}
.Main .Info a {
  text-decoration: underline;
}
.Main .Info a:hover {
  transition: all ease 0.2s;
  color: black;
}

.Main .Images {
  grid-column: 2;
  grid-row: 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 24px;
}
.Main .Images .ImgL {
  height: 44vw;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  grid-column: span 2;
}
.Main .Images .ImgV {
  height: 50vw;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  grid-column: span 1;
}
.Main .Video {
  cursor: pointer;
  height: 44vw;
  grid-column: 2;
  background-color: black;
}
.Video iframe {
  cursor: pointer;
  height: 44vw;
  grid-column: 2;
}

@media (max-width: 800px) and (min-width: 700px) {
  .Main {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 60vw auto;
      padding: 36px 36px;
  }
  .Main .HeroImg {
      grid-column: 1 / span 2;
      grid-row: 1 / span 2;
      height: 60vw;
  }
  .Main .Info {
      grid-column: 1 / span 1;
      grid-row: 2 / span 1;
  }
  .Main .Images {
      grid-column: 2 / span 1;
      grid-row: 2 / span 1;
      display: block;
  }
  .Main .Images .ImgL, .ImgV {
      margin-bottom: 24px;
  }
  .Main .Images div:last-child {
      margin-bottom: 0;
  }
  .Main .Images .ImgL {
      height: 30vw;
  }
  .Main .Images .ImgV {
      height: 70vw;
  }
  .Main .Video {
      height: 60vw;
      grid-column: 1 / span 2;
  }
  .Video iframe {
      height: 60vw;
      grid-column: 1 / span 2;
  }
}

@media (max-width: 700px) {
  .Main {
      align-self: flex-start;
      margin: 0px;
      margin-top: 10vh;
      padding: 5vw;
      grid-template-columns: 1fr;
      grid-template-rows: 60vw auto auto;
      row-gap: 5vw;
  }
  .Main .HeroImg {
      height: 60vw;
      grid-column: 1;
      grid-row: 1;
  }
  .Main .Info {
      padding-right: 0px;

      grid-column: 1;
      grid-row: 2;
  }
  .Main .Info h1 {
      color: var(--yellow);
      margin: 0px;
      font-size: 5vw;
      line-height: 8vw;
      margin-bottom: 5px; 
  }
  .Main .Info h3 {
      color: var(--white);
      margin: 0px;
      font-weight: 500;
      font-size: 3.5vw;
      line-height: 5vw;
  }
  .Main .Info p {
      color: var(--white);
      font-weight: 300;
      font-size: 3vw;
      line-height: 5vw;
  }
  .Main .Images {
      grid-column: 1;
      grid-row: 3;

      display: block;
  }
  .Main .Images .ImgL, .ImgV {
      margin-bottom: 5vw;
  }
  .Main .Images div:last-child {
      margin-bottom: 0;
  }
  .Main .Images .ImgL {
      height: 60vw;
  }
  .Main .Images .ImgV {
      height: 140vw;
  }
  .Main .Video {
      height: 60vw;
      grid-column: 1;
  }
  .Video iframe {
      height: 60vw;
      grid-column: 1;
  }
} */
