.ProjectsContainer{
    margin: 200px 0px 0px 0px;
    width: 50%;
    display: block;
    padding: 36px;
}
.ProjectsContainer h2 {
    font-size: 20px;
    color: black
}
.ProjectsContainer h1 {
    margin-top: 0px;
    font-size: 30px;
    color: black
}

/* HEADER */
.ProjectImg {
    height: 100px;
    min-width: 150px;
    background-size: cover;
    background-position: center;
    margin-right: 10px;

    background-color: var(--yellow);
}
.Header {
    background-color: #fff;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);

    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    margin: 20px 0px;
    padding-left: 0px;
}
.Arrows svg {
    font-size: 25px;
    margin: 0 20px;
    margin-right: 0px;
    cursor: pointer;
}

@media (max-width: 1100px) and (min-width: 700px) {
    .ProjectsContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .Header, .MainSubmitButton {
        width: 70vw;
    }
}

@media (max-width: 700px) {
    .ProjectsContainer{
        margin: 60px 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .Header, .MainSubmitButton {
        width: 90vw;
        position: relative;
    }
    .Header {
        padding: 0px;
    }
    .Header h2 {
        font-size: 5vw;
    }
    .Arrows {
        flex-direction: column;
        justify-content: space-between;
        height: 80%;
        margin-right: 10px;
    }
    .Arrows svg {
        font-size: 5vw;
        margin: 0;
    }
}