.ReorderProjectsConfirmation {
    margin: 200px 0px 0px 0px;
    width: 50%;
    height: fit-content;
    display: block;
    text-align: center;
    padding: 36px;

    background-color: #fff;
}

.ReorderProjectsConfirmation p{
    font-size: 20px;
}

@media (max-width: 700px) {
    .ReorderProjectsConfirmation {
        margin: 50px 0px;
        width: 90%;
    }
    .ReorderProjectsConfirmation p{
        font-size: 14px;
    }
}