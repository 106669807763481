@font-face {
  font-family: 'DINPro-Light';
  src: local('DINPro-Light'), url(./fonts/DINPro-Light.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'DINPro-Medium';
  src: local('DINPro-Medium'), url(./fonts/DINPro-Medium.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'DINPro-Regular';
  src: local('DINPro-Regular'), url(./fonts/DINPro-Regular.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'DINPro-Bold';
  src: local('DINPro-Bold'), url(./fonts/DINPro-Bold.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'DINPro-Medium', sans-serif;
}
p {
  font-family: 'DINPro-Light', sans-serif;
}
strong {
  font-family: 'DINPro-Medium', sans-serif;  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
