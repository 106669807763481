.Main {
    position: relative;
    width: 100%;
}
.Tag {
    width: 100%;
    height: 1px;
    transform: translateY(-160px);
}
@media (max-width: 700px) {
    .Tag {
        transform: translateY(-50px);
    }
}
/* OUR CENTRE */
.OurCentre {
    height: fit-content;
    margin-top: 160px;
    width: 100%;
    box-sizing: border-box;
    padding: 72px 36px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 20px;
}

.OurCentre .Info {
    padding-right: 36px;
}

.OurCentre .Info h1 {
    font-size: 20px;
    color: var(--yellow);
    margin: 0px;
    line-height: 40px;
    margin-bottom: 22px; 
}
.OurCentre .Info p {
    font-size: 14px;
    color: var(--white);
    line-height: 28px;
}

.OurCentre .Image {
    height: 44vw;

    /* background-image: url('../../assets/team-photo.jpg'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

}

.Email {
    text-decoration: underline;
    cursor: pointer;
}

.Email:hover{
    transition: all ease 0.2s;
    color: black;
}

@media (max-width: 800px) and (min-width: 700px) {
    .OurCentre {
        margin-top: 160px;
    }
}
@media (max-width: 700px) {
    .OurCentre {
        margin-top: 10vh;
    }
}

@media (max-width: 800px) {
    .OurCentre {
        width: 100%;
        padding: 5vw;
        grid-template-columns: 1fr;
        row-gap: 5vw;
    }
    .OurCentre .Info {
        padding-right: 0px;
    }
    .OurCentre .Info h1 {
        font-size: 5vw;
        line-height: 8vw;
    }
    .OurCentre .Info p {
        font-size: 3vw;
        line-height: 5vw;
    }
    
    .OurCentre .Image {
        display: block;
        height: 60vw;
    }
}

/* OUR TEAM */
.OurTeam {
    height: fit-content;
    width: 100%;
    box-sizing: border-box;
    padding: 72px 36px;
}

.OurTeam .Info {
    padding-right: 36px;
}

.OurTeam .Info h1 {
    font-size: 20px;
    color: var(--yellow);
    margin: 0px;
    line-height: 40px;
    margin-bottom: 22px; 
}
.OurTeam .Info h2 {
    font-size: 18px;
    color: var(--white);
    margin: 0px;
    line-height: 36px;
}
.OurTeam .Info h3 {
    font-size: 16px;
    color: var(--yellow);
    margin: 0px;
    line-height: 32px;
}
.OurTeam .Info p {
    font-size: 14px;
    color: var(--white);
    line-height: 28px;
}
.OurTeam .Staff p {
    font-size: 14px;
    color: var(--white);
    line-height: 28px;
    margin: 0;
}
.OurTeam .Staff .JobTitle {
    font-family: "DINPro-Medium";
}
.OurTeam .Staff {
    margin: 22px 0px;
}

.OurTeam .StaffContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
}
.OurTeam .Incubees {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}
.PastIncubees {
    margin-top: 44px;
}
.OurTeam .ExpandButton {
    display: flex;
    align-items: center;
    margin: 22px 0px;
    cursor: pointer;

    /* justify-content: space-between; */
    /* padding: 10px; */
    /* background-color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px; */
}
.OurTeam .ExpandButton .Chevron{
    margin-left: 22px;
}

.OurTeam .ExpandButton h3 {
    color: var(--white);
}
.OurTeam .ExpandButton .Chevron, .OurTeam .ExpandButton {
    color: var(--white);
}
.List {
    display: none;
    padding-bottom: 22px;
}
.Year.Hidden .List {
    opacity: 0;
    transform: translateY(-25px);
    transition: transform ease 0.5s, opacity ease 0.3s;
}
.Year.Shown .List {
    opacity: 1;
    transform: translateY(0px);
    transition: transform ease 0.5s, opacity ease 0.3s;
}
.Year.Hidden .Chevron {
    transform: rotate(0deg);
    transition: transform ease 0.5s;
}
.Year.Shown .Chevron {
    transform: rotate(180deg);
    transition: transform ease 0.5s;
}

@media (max-width: 800px) {
    .OurTeam{
        margin-top: 5vh;
        padding: 5vw;
    }
    .OurTeam .StaffContainer{
        grid-template-columns: 1fr;
        row-gap: 5vw;
    }
    .OurTeam .PastIncubees {
        margin-top: 0;
    }
    .OurTeam .Info {
        padding-right: 0px;
    }
    .OurTeam .Info h1 {
        font-size: 5vw;
        line-height: 8vw;
    }
    .OurTeam .Info h2 {
        font-size: 4vw;
        line-height: 6vw;
    }
    .OurTeam .Info h3 {
        font-size: 3.5vw;
        line-height: 5vw;
    }
    .OurTeam .Info p {
        font-size: 3vw;
        line-height: 5vw;
    }
    .OurTeam .Chevron {
        font-size: 3vw;
    }
}

/* CONTACT US */
.ContactUs {
    height: fit-content;
    width: 100%;
    box-sizing: border-box;
    padding: 72px 36px;
}

.ContactUs h1 {
    font-size: 20px;
    color: var(--yellow);
    margin: 0px;
    line-height: 40px;
    margin-bottom: 22px; 
}
.ContactUs .Info h2 {
    font-size: 18px;
    color: var(--white);
    margin: 0px;
    line-height: 36px;
}
.ContactUs h3 {
    font-size: 16px;
    color: var(--yellow);
    margin: 0px;
    line-height: 32px;
}
.ContactUs p {
    margin: 0px;
    font-size: 14px;
    color: var(--white);
    line-height: 28px;
}

.ContactUs .ContactContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 20px;
}
.ContactUs .Info {
    display: flex;
    flex-direction: column;
}
.ContactUs .Map {
    height: 44vw;
    border: 0;
}

@media (max-width: 800px) {
    .ContactUs {
        margin-bottom: 5vh;
        padding: 5vw;
    }
    .ContactUs .ContactContainer {
        grid-template-columns: 1fr;
        row-gap: 5vw;
    }
    .ContactUs .Info {
        padding-right: 0px;
    }
    .ContactUs .Info h1 {
        font-size: 5vw;
        line-height: 8vw;
    }
    .ContactUs .Info h2 {
        font-size: 4vw;
        line-height: 6vw;
    }
    .ContactUs .Info h3 {
        font-size: 3vw;
        line-height: 5vw;
    }
    .ContactUs .Info p {
        font-size: 3vw;
        line-height: 5vw;
    }
    .ContactUs .Map {
        height: 60vw;
    }
}
