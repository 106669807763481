
.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 160px;
    width: 500px;
    padding: 72px 36px;
}

.Button {
    background-color: black;
    color: white;
    cursor: pointer;
    padding: 20px;
    width: 100%;
    font-family: 'DINPro-Light', sans-serif;
    margin: 10px 0;
    font-size: 20px;
    outline: none;
    border: none;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2); 
    text-align: center;
}

.Button a {
    width: 100%;
    height: 100%;
}

.Button:hover{
    transform: scale(1.01);
}
.Button:active{
    transform: scale(0.99);
}

@media (max-width: 800px) and (min-width: 700px) {
    .Container {
        padding: 36px 36px;
    }
}

@media (max-width: 700px) {
    .Container {
        margin: 0px;
        margin-top: 10vh;
        padding: 5vw;
        width: 80vw;
    }

}