.Main,
.MainEmpty {
  margin-top: 160px;
  width: 100%;
  padding: 72px 36px 0px 36px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 24px;
}

.Project {
  height: 26.7vw;
  /* border: 1px solid white; */
  background-color: var(--white);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
  cursor: pointer;
  text-decoration: none;
}

.Project:hover {
  transform: scale(1.01);
  transition: all ease 0.2s;
}
.Project:hover .Text {
  color: black;
}

.Project:active {
  background-color: var(--yellow);
  transform: scale(1);
  transition: all ease 0.2s;
}

.Project .Img {
  height: 75%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.Project .Text {
  height: 25%;
  padding: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--grey);
}
.Project h1,
.Project p {
  margin: 0px;
}
.Project h1 {
  font-size: 1.2vw;
  /* font-size: 16px; */
  align-self: flex-start;
}
.Project p {
  font-size: 1vw;
  /* font-size: 14px; */
  align-self: flex-start;
}

.NoProject {
  color: var(--white);
  text-align: center;
  width: 100%;
  grid-column: 1 / span 3;
}

@media (max-width: 800px) and (min-width: 700px) {
  .Main,
  .MainEmpty {
    grid-template-columns: 1fr 1fr;
  }
  .Project {
    height: 38vw;
  }
  .Project .Text {
    padding: 1.5vw;
  }
  .Project h1 {
    font-size: 1.5vw;
  }
  .Project p {
    font-size: 1.2vw;
  }
}

@media (max-width: 700px) {
  .Main {
    align-self: flex-start;
    margin: 0px;
    margin-top: 10vh;
    padding: 5vw;
    grid-template-columns: 1fr;
    row-gap: 5vw;
  }
  .MainEmpty {
    align-self: center;
    margin: 0px;
    margin-top: 0px;
    padding: 5vw;
    grid-template-columns: 1fr;
    row-gap: 5vw;
  }
  .Project {
    height: 78vw;
  }
  .Project .Text {
    padding: 5%;
  }
  .Project h1 {
    font-size: 3.5vw;
    /* padding-bottom: 5px; */
  }
  .Project p {
    font-size: 3vw;
  }
}
