form{
    margin: 200px 0px 0px 0px;
    width: 50%;
    display: block;
    padding: 36px;
}

input, textarea, button, select {
    width: 100%;
    font-family: 'DINPro-Light', sans-serif;
    margin: 5px 0;
    font-size: 20px;
    padding: 10px;
    outline: none;
    border: none;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);
}

input::placeholder, textarea::placeholder, select::placeholder {
    opacity: 0.5;
}

input:focus, textarea:focus {
    box-shadow: none;
}

form h1 {
    color: var(--yellow);
}
form h2 {
    color: var(--white);
}
.ImgThumbnail {
    height: 100px;
    width: 200px;
    margin-right: 10px;
    background-size: cover;
    background-position: center;

    position: relative;
}
.ImageSubmit {
    background-color: #fff;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);
    padding: 10px;
    margin-bottom: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    justify-content: center;
}
.ImageSubmit h3 {
    margin: 5px 0px;
    /* padding: 0; */
}
.UploadImages {
    box-shadow: none;
    padding: 0px;
}

.Category {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 220px
}
.Chevron {
    color: var(--white);
    font-size: 18px;
}

.Delete {
    display: flex;
    align-items: center;
}
.DeleteButton, .AddButton {
    background-color: black;
    color: white;
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    padding: 20px;
    width: 135px;
    margin-top: 0;
}
.DeleteConfirmation {
    margin-left: 20px;
    display: none;
}
.DeleteConfirmation p {
    color: var(--black);
    font-size: 20px;
    margin: 0px 10px;
}
.DeleteYes, .DeleteNo {
    transition: all 0.2s ease;
    cursor: pointer;
}
.DeleteYes:hover, .DeleteNo:hover {
    transform: scale(1.01);
    border-bottom: 2px solid black;
    cursor: pointer;
}
.DeleteYes:active, .DeleteNo:active {
    transform: scale(0.99);
    border-bottom: 2px solid black;
    cursor: pointer;
}

.Plus {
    font-size: 25px;
    margin: 20px 0;
    cursor: pointer;
    color: var(--yellow);
}

button {
    margin-top: 20px;
    background-color: black;
    color: white;
    cursor: pointer;
    padding: 20px;
}
button:hover{
    transform: scale(1.01);
}
button:active{
    transform: scale(0.99);
}

@media (max-width: 700px) {
    form{
        margin: 60px 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    input, textarea, button {
        width: 100%;
    }
    
    .Delete {
        display: block;
    }
    .DeleteConfirmation {
        padding-top: 10px;
        margin-left: 0;
        justify-content: center;
    }
    .DeleteNo, .DeleteYes {
        border-bottom: 1.5px solid black;
    }
    .DeleteButton {
        width: 100%;
    }

    .Category {
        width: 100%;
    }
}