footer {
    font-size: 12px;
    width: 100%;
    padding: 36px 0px;
    display: flex;
    justify-content: center;
    
    /* position: absolute;   
    bottom: 0;  */

    background-color: var(--grey);
    color: var(--white);
}

@media (max-width: 800px) and (min-width: 700px) {
    footer {
        padding: 36px 0px;
    }
}

@media (max-width: 700px) {
    footer {
        padding: 5vw;
        font-size: 2.5vw;
    }
}