* {
  box-sizing: border-box;
}

:root {
  --yellow: rgba(255, 207, 1, 1);
  --grey: rgb(140, 141, 143);
  --light-grey: rgb(207, 207, 207);
  --greyTransparent: rgba(140, 141, 143, 0.9);
  --greyDark: rgb(75, 75, 75);
  --white: rgba(255, 255, 255, 0.9);
}

body {
  font-family: "DINPro-Light", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  margin: 0;

  background-color: var(--grey);
}

/* NAV BAR */
nav {
  width: 100%;
  min-height: 160px;
  padding: 36px 36px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 20px;
  position: fixed;

  background-color: var(--greyTransparent);
  z-index: 100;
  left: 0;
  top: 0;
}

nav h1 {
  color: var(--yellow);
  margin: 0px;
  margin-bottom: 5px;
  font-size: 18px;
}
nav ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}
nav li a {
  width: fit-content;
  color: var(--white);
  font-size: 16px;
  font-weight: 100;

  cursor: pointer;
}
nav li a:hover {
  transform: scale(1.01);
  transition: all ease 0.2s;
  color: black;
}
nav li a:active {
  transform: scale(0.99);
  transition: all ease 0.2s;
}

/* FIRST COLUMN */
.FirstNavDiv {
  max-height: 88px;
  display: flex;
  align-items: center;

  position: relative;
}
.Logo {
  width: 75px;
  height: 90%;
  /* background-color: var(--grey); */
  background-image: url(../../assets/DIC_Logo_White-01.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
  cursor: pointer;
}
.LogoText {
  position: absolute;
  left: 75px;

  opacity: 0;
  transform: translateX(-50px);
  transition: all 0.5s ease;

  height: 90%;
  width: 100%;
  background-image: url(../../assets/DIC_Text_White-01.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;

  z-index: -1;
}

/* OTHER COLUMNS */
.OtherNavDivs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
}

/* LAST COLUMN */
.NavDiv:last-child {
  display: flex;
  justify-content: space-between;
}
.fbIcon,
.instaIcon {
  margin-bottom: 15px;
  font-size: 36px;
  color: var(--yellow);
  cursor: pointer;
}
.fbIcon:hover,
.fbIcon:active,
.instaIcon:hover,
.instaIcon:active {
  transition: all ease 0.2s;
  color: black;
}

.upIcon,
.menuIcon {
  display: none;
}

@media (min-width: 700px) and (max-width: 800px) {
  nav {
    grid-template-columns: 3fr 5fr;
  }
}

@media (max-width: 700px) {
  /* NAV BAR */
  nav.Hidden {
    transform: translateY(-80%);
    position: fixed;
  }
  nav.Hidden .NavDiv {
    opacity: 0;
  }
  nav.Hidden .upIcon {
    opacity: 0;
  }
  nav.Hidden .menuIcon {
    display: flex;
    align-items: center;
    height: 10%;
    bottom: 5%;
    left: 0;
    position: absolute;
  }

  nav {
    transform: translateY(0);
    transition: transform ease 0.5s;
    height: 50%;
    min-height: fit-content;
    grid-template-rows: 1fr 2fr;
    grid-template-columns: 1fr;
    grid-row-gap: 5vw;
    padding: 5vw;
    padding-bottom: 10vw;
  }
  .NavDiv {
    display: flex;
    align-items: flex-start;
    opacity: 1;
    transition: opacity ease 0.5s;
  }
  .OtherNavDivs {
    grid-row: 2;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 5vw;
    z-index: -1;
  }

  nav .SocialIcons {
    display: flex;
  }
  .instaIcon,
  .fbIcon {
    margin: 10px;
    margin-left: 0px;

    margin-bottom: 0px;
    font-size: 7vw;
    color: var(--yellow);
  }
  .Logo {
    background-image: url("../../assets/DIC_LogoType_White-01.png");
    width: 100%;
    height: 75%;
    background-position: left;
  }
  .LogoText {
    display: none;
  }

  nav h1 {
    /* font-size: 3.5vw; */
    font-size: 1.2em;
    margin: 0px;
  }
  nav li a {
    /* font-size: 3vw; */
    /* line-height: 5vw; */
    font-size: 1em;
    line-height: 1.5em;
  }

  .upIcon {
    display: block;
    position: absolute;
    left: 5vw;
    bottom: 6vw;
    /* font-size: 5vw; */
    font-size: 1.5em;
    color: var(--white);
  }
  .menuIcon {
    width: 0.875em;
    margin-left: 5vw;
    /* font-size: 5vw; */
    font-size: 1.5em;
    color: var(--white);
    z-index: 100;
  }
}

@media (max-width: 350px) {
  nav h1,
  .upIcon,
  .menuIcon {
    font-size: 1em;
  }
  nav li a {
    font-size: 0.8em;
    line-height: 1em;
  }
}
